import Image1 from '../assests/blogs/mongodbBlog.jpg';
import Image2 from '../assests/blogs/spiceupworkBlog.jpg'
import Image3 from '../assests/blogs/graphqlBlog.jpg'
import Image4 from '../assests/blogs/planprojectBlog.jpg'
import Image5 from '../assests/blogs/widgetBlog.jpg'
import Image6 from '../assests/blogs/javascript.jpg'
import Image7 from '../assests/blogs/cleanCode.jpg'
import Image8 from '../assests/blogs/exploring _bigCommerce _graphQL_queries.jpg'
import Image9 from '../assests/blogs/listing_blogs_with_graphql.jpg'
import Image10 from '../assests/blogs/b2bEditionApp.jpg'

const blogList = [
    {
        id:10,
        name:"BigCommerce B2B Edition Features and Installation",
        src :Image10,
        url:"https://www.linkedin.com/pulse/bigcommerce-b2b-edition-features-installation-sahil-pate-z3ync/",
        content : "The B2B Edition is a SaaS application created by BigCommerce that offers enterprise-level business-to-business functionalities. These features are applicable to businesses of all sizes, regardless of whether they are small or large. The B2B Edition app is included by BigCommerce in their standard B2B Edition package. It can also be added to an existing store by installing the app from the BigCommerce Apps Marketplace or by contacting the BigCommerce team."
    },
    {
        id:9,
        name:"Listing Blogs with GraphQL in BigCommerce widgets",
        src :Image9,
        url:"https://www.linkedin.com/pulse/listing-blogs-graphql-bigcommerce-widgets-sahil-pate-cnarf/",
        content : "This article will help you to understand how to integrate a blog with GraphQl in BigCommerce. We all know that the widgets are reusable components in the BigCommerce project. These can be used on multiple pages. The traditional way of displaying blogs on a website is by using stencil objects. But to show them on different pages, we must add code to every template. So here is a solution developing a custom widget that fetches the blogs using storefront GraphQl API."
    },
    {
        id:8,
        name:"Exploring BigCommerce GraphQL Queries",
        src :Image8,
        url:"https://www.linkedin.com/pulse/exploring-bigcommerce-graphql-queries-sahil-pate-hxscf/",
        content : "In this article, we will dive into the powerful capabilities of BigCommerce's Storefront API, specifically focusing on GraphQL queries to interact with e-commerce data. BigCommerce provides a robust API that allows developers to access and manipulate store data efficiently. GraphQL, with its flexibility and efficiency in data fetching, is an ideal choice for working with BigCommerce's Storefront API."
    },
    {
        id:7,
        name:"The Essential Guide to Writing Clean Code",
        src :Image7,
        url:"https://www.linkedin.com/pulse/essential-guide-writing-clean-code-sahil-pate-pwzsf/",
        content : "Every day, developers like us write anywhere from 100 to 1000 lines of code. But do we truly know whether the code we write is proper or not? The significance of crafting proper and easily understandable code cannot be overstated. This article aims to help you with the knowledge and techniques necessary to consistently produce clean code."
    },
    {
        id:6,
        name:"How JavaScript Works Behind the Scenes",
        src :Image6,
        url:"https://www.linkedin.com/pulse/how-javascript-works-behind-scenes-sahil-pate-bfwwf/",
        content : "Have you ever wondered how the javascript works behind the scenes? This article will help you to understand how JavaScript works behind the scenes. You will learn what Execution context and Call stack are and also, how these things work."
    },
    {
        id:5,
        name:"Connecting MongoDB with Node JS",
        src :Image1,
        url:"https://www.linkedin.com/pulse/connecting-mongodb-node-js-sahil-pate/",
        content : "Hello, Everyone!\n" +
            "This article will help you to understand how to connect MongoDB with Node JS. The MongoDB is a database that is classified as a NoSQL. It uses JSON format to store the data in the database.\n" +
            "First, we will set up a Node js server and then connect it with the MongoDB. We will require the following tools:"
    },
    {
        id:4,
        name:"Spice Up Your Work Developers: A Guide to Making Your Work More Interesting",
        src :Image2,
        url :"https://www.linkedin.com/pulse/spice-up-your-work-developers-guide-making-more-interesting-pate/",
        content : "Hello Everyone,\n" +
            "I am writing this blog to make your work interesting, especially for developers. We know developers do coding, and development regularly. At some point, it feels like getting bored with all things and lowers interest. It is going to affect all your productivity and things will take more time than usual."
    },
    {
        id:3,
        name:"Integrating BigCommerce Storefront API using GraphQL",
        src:Image3,
        url :"https://www.linkedin.com/pulse/integrating-bigcommerce-storefront-api-using-graphql-sahil-pate/",
        content : "Hello, Everyone!\n" +
            "This article will help you to understand how to integrate a Bigcommerce Storefront API using GraphQL inside a storefront theme. Storefront API is used to manage cart operations, manage checkout operations, create a subscription, etc.\n" +
            "A GraphQL storefront API is used to access storefront data such as"
    },
    {
        id:2,
        name:"How to Plan Your Project?",
        src:Image4,
        url :"https://www.linkedin.com/pulse/how-plan-your-project-sahil-pate/",
        content : "Hello, Everyone!\n" +
            "This article will help you to understand how to plan your project. Planning your project is very important nowadays. Proper planning of a project plan helps us to understand the required time, cost, and resources. Also help us to understand how to define a project, how to divide a task, how to manage time, and many more.\n" +
            "The project planning is explained in the below steps:"
    },
    {
        id:1,
        name:"Create widget using Widget Builder tool in BigCommerce",
        src:Image5,
        url :"https://www.linkedin.com/pulse/create-widget-using-builder-tool-bigcommerce-sahil-pate/",
        content : "Hello, Everyone!\n" +
            "This article will help you to understand how to create a custom widget using widget-builder which is a command-line tool.\n" +
            "You are all aware of the existing method of creating custom widgets through API by using applications such as Postman, Insomnia, etc. This is quite a complex method as we need to combine schema, and HTML in a single file. Also, we need to create different requests to update and create a widget."
    }
]
export default blogList;
