import blogsData from "../../data/blogsData";
import '../../sass/blogPage/blogList.scss'
import {Link} from "react-router-dom";
import {useState} from "react";
import {CiSearch} from "react-icons/ci";
import {IoMdClose} from "react-icons/io";

const BlogsListComponent = ()=> {

    const [query, setQuery] =useState('');

    const filterData = blogsData.filter((e)=> e.name.toLowerCase().includes(query.toLowerCase()));

    const removeFilter =()=>{
        setQuery('');
        document.getElementById('search-field').value = '';
    }

    return(
        <>
            <div className={'container'}>
                <div className={'search-div'}>
                    <div className={'input-field'}>
                        <input placeholder={'Type to search'} id={'search-field'} onChange={(e)=> setQuery(e.target.value)}/>
                        <CiSearch />
                    </div>
                    {query.length > 0 &&
                    <button type={"button"} onClick={removeFilter}><IoMdClose />Clear search</button>
                    }
                </div>
            </div>
            <section className={'blogListComponent'}>
                <div className={'container'}>
                    {filterData.length ?
                    <ul className={'list'}>
                        {filterData.map((e)=>(
                            <li className={'list-item'}>
                                <Link to={e.url} target={'_blank'} className={'blog-content'}>
                                    <img src={e.src} alt={e.name}/>
                                    <h3 className={'name'}>{e.name}</h3>
                                    <p className={'content'}>{e.content.substring(0, 200) + '...'}</p>
                                    <span className={'readMore-btn'}>Read more</span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                        :
                        <p className={'message'}>No data found</p>
                    }
                </div>
            </section>
        </>
    )
}
export default BlogsListComponent;
