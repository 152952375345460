import {useEffect, useState} from 'react';
import {getAuth, onAuthStateChanged} from 'firebase/auth';

const useUser = () => {
    const [user, setUser] = useState(null);
    useEffect(() => {
        return onAuthStateChanged(getAuth(), user => {
            setUser(user);
        });
    }, []);

    return { user };
}

export default useUser;
