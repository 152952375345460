import '../sass/components/banner.scss'
const Banner = ({Heading})=>{

    return(
        <div className={'page-background'}>
            <div className={'container'}>
                <h1>{Heading}</h1>
            </div>
        </div>
    )
}

export default Banner;
