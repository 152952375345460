import './App.css';
import './sass/global.scss';
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import { useEffect } from 'react';
import Header from "./components/header";
import Footer from "./components/footer";
import HomePage from "./pages/HomePage";
import BlogsPage from "./pages/BlogsPage";
import ResumePage from "./pages/ResumePage";
import AboutPage from "./pages/aboutPage";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {IoIosArrowUp} from "react-icons/io";
import NotFoundPage from "./pages/notFoundPage";
import ReviewPage from "./pages/reviewsPage";
import Weather from "./components/Projects/weatherApp";
import LoginPage from "./pages/loginPage";
import SignUpPage from "./pages/signupPage";

function App() {
    // scroll to top on location change
    function ScrollToTop() {
        const location = useLocation();
        useEffect(() => {
            // scroll to top
            window.scrollTo(0, 0);

            // update meta title on location changed
            const path = location.pathname.replace('/','');
            if (path === ''){
                document.title = 'Sahil Pate';
            } else if(path === 'blogs'){
                document.title = "Blogs";
            } else if(path === 'about'){
                document.title = "About";
            } else if(path === 'reviews'){
                document.title = "Testimonials";
            } else if(path === 'weather'){
                document.title = "Weather App";
            }  else if(path === 'login'){
                document.title = "Log in";
            } else if(path === 'sign-up'){
                document.title = "Sign up";
            } else {
                document.title = 'Not Found';
            }
        }, [location]);

        return(
            <button onClick={()=>window.scrollTo(0,0)} className={'scrollUpButton'}><IoIosArrowUp /></button>
        )
    }

    return (
        <BrowserRouter>
            <Header/>
            <div className="page-body">
                <Routes>
                    <Route index element={<HomePage/>}/>
                    <Route path="/about" element={<AboutPage/>}/>
                    <Route path="/blogs" element={<BlogsPage/>}/>
                    <Route path="/resume" element={<ResumePage/>}/>
                    <Route path="/reviews" element={<ReviewPage/>}/>
                    <Route path="/weather" element={<Weather/>}/>
                    <Route path="/login" element={<LoginPage />}/>
                    <Route path="/sign-up" element={<SignUpPage />}/>
                    <Route path="*" element={<NotFoundPage/>}/>
                </Routes>
            </div>
            <Footer/>
            <ScrollToTop/>
        </BrowserRouter>
    );
}

export default App;
