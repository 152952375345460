const ResumePage = ()=> {
    return(
        <div>
            Resume
        </div>
    )
}

export default ResumePage;


