import LogInWithEmailAndPassword from "../components/account/logInWithEmailAndPassword";
import LogInWithGoogle from "../components/account/logInWithGoogle";
import '../sass/account/loginPage.scss'
import {Link} from "react-router-dom";
import PageHeading from "../components/pageHeading";

const LoginPage = ()=>{
    return(
        <>
            <section className={'account'}>
                <div className={'container'}>
                    <div className={'login-section'}>
                        <LogInWithEmailAndPassword/>
                        <LogInWithGoogle />
                        <Link to={'/sign-up'} className={'account-link'} >Don't have an account? <span>Register</span></Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoginPage;
