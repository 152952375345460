import '../../sass/components/capabilities.scss'
import WebDesignImg from '../../assests/webdesign.gif'
import ArticleImg from '../../assests/technicalwriting.gif'
import EcommerceImg from '../../assests/ecommerce.gif'

const Capability = ()=> {
    const capabilityList = [
        {
            id:1,
            name:"Website Development",
            src :WebDesignImg
        },
        {
            id:2,
            name:"Ecommerce Development",
            src :EcommerceImg
        },
        {
            id:3,
            name:"Technical Article Writing",
            src:ArticleImg
        }
    ]
    return(
        <section className={'capability-section'}>
            <div className={'container'}>
                <div className={'capability-section-wrap'}>
                    <div className={'content'}>
                        <h2 className={'title'}>Core Capabilities</h2>
                    </div>
                    <ul className={'list'}>
                        {capabilityList.map((e)=>(
                            <li className={'list-item'} key={e.id}>
                                <div className={'block'}>
                                    <img src={e.src} alt={e.name} loading={'lazy'}/>
                                    <h3 className={'name'}>{e.name}</h3>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Capability;
