import {Link} from "react-router-dom";
import Logo from '../logo.svg';
import {RxHamburgerMenu} from "react-icons/rx";
import {AiOutlineClose} from "react-icons/ai";
import {useState} from "react";
import {FaLinkedinIn, FaUserCircle} from "react-icons/fa";
import {BsTwitter} from "react-icons/bs";
import useUser from "./account/useUser";
import {auth} from "../firebase";
import {RiShutDownLine} from "react-icons/ri";

const Header = () => {

    const {user} = useUser();

    const Logout =()=>{
        auth.signOut();
    }

    window.onscroll = function () {
        scrollIndicator()
    };

    function scrollIndicator() {
        const winScroll = window.pageYOffset || document.documentElement.scrollTop;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        let scrolled = 100;

        if (height > 0) {
            scrolled = (winScroll / height) * 100;
        }

        // Assuming "bar" is an element you want to update the height of
        let bar = document.getElementById("bar");
        if (bar) {
            bar.style.width = scrolled + "%";
        }
        if (winScroll > 50) {
            document.querySelector('.scrollUpButton').classList.add('visible');
        } else if (winScroll < 50) {
            document.querySelector('.scrollUpButton').classList.remove('visible');
        }
    }

    const [openMenu, setOpenMenu] = useState(false);
    const downloadResume = () => {
        fetch("../../../resume.pdf").then((response) => {
            response.blob().then((blob) => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = "Sahil-Pate-Resume.pdf";
                alink.click();
            });
        });
    };
    return (
        <>
            <header className={"site-header"}>
                <div className={'mobile-header'}>
                    <span className={'toggle-menu'} to={'/'} onClick={() => setOpenMenu(true)}>
                        <RxHamburgerMenu />
                    </span>
                    <div className={'logo'}>
                        <Link to={'/'}>
                            <img src={Logo} alt={'sahil pate'}/>
                        </Link>
                    </div>
                    <nav className={`mobile-navigation ${openMenu ? 'open' : ''}`}>
                        <div className={'mobile-navigation-wrapper'}>
                            <AiOutlineClose className={'close-button'} onClick={() => setOpenMenu(false)}/>
                            <ul className={"navigation-list"}>
                                <li className={"navigation-list-item"}>
                                    <Link to={"/"} className={"navigation-link"}
                                          onClick={() => setOpenMenu(false)}>Home</Link>
                                </li>
                                <li className={"navigation-list-item"}>
                                    <Link to={"/about"} className={"navigation-link"}
                                          onClick={() => setOpenMenu(false)}>About</Link>
                                </li>
                                <li className={"navigation-list-item"}>
                                    <Link to={"/blogs"} className={"navigation-link"}
                                          onClick={() => setOpenMenu(false)}>Blogs</Link>
                                </li>
                                <li className={"navigation-list-item"}>
                                    <Link to={"/reviews"} className={"navigation-link"}
                                          onClick={() => setOpenMenu(false)}>Testimonials</Link>
                                </li>
                                <li className={"navigation-list-item"}>
                                    <Link to={'#'} className={'navigation-link'} onClick={downloadResume}>
                                        Resume
                                    </Link>
                                </li>
                                <li className={"navigation-list-item"}>
                                    {user ?
                                        <span className={'navigation-link'} onClick={Logout}>Logout</span>
                                        :
                                        <Link to={"/login"} className={"navigation-link"}
                                              onClick={() => setOpenMenu(false)}>Login</Link>
                                    }

                                </li>
                            </ul>
                            <div className={'social-section'}>
                                <h4 className={'social-title'}>Let's Connect</h4>
                                <ul className={'social-list'}>
                                    <li className={'social-list-item'}>
                                        <a className={'link'} href={'mailto:connect@sahilpate.dev'}>connect@sahilpate.dev</a>
                                    </li>
                                    <li className={'social-list-item'}>
                                        <Link className={'link icon'} to={'https://www.linkedin.com/in/sahil-pate/'} target={'_blank'}><FaLinkedinIn /></Link>
                                        <Link className={'link icon'} to={'https://twitter.com/sahilpate7'} target={'_blank'}><BsTwitter /></Link>
                                    </li>
                                </ul>

                            </div>
                        </div>

                    </nav>
                </div>
                <div className={'container'}>
                    <div className={'desktop-header'}>
                        <div className={"logo"}>
                            <Link to={'/'}>
                                <img src={Logo} alt={'sahil pate'}/>
                            </Link>
                        </div>
                        <nav className={"main-navigation"}>
                            <ul className={"navigation-list"}>
                                <li className={"navigation-list-item"}>
                                    <Link to={"/"} className={"navigation-link"}>Home</Link>
                                </li>
                                <li className={"navigation-list-item"}>
                                    <Link to={"/about"} className={"navigation-link"}>About</Link>
                                </li>
                                <li className={"navigation-list-item"}>
                                    <Link to={"/blogs"} className={"navigation-link"}>Blogs</Link>
                                </li>
                                
                                <li className={"navigation-list-item"}>
                                    {user ?
                                        <span title={'Log Out'} className={"account-link"} onClick={Logout} ><RiShutDownLine /></span>
                                        :
                                    <Link to={"/login"} className={"account-link"}><FaUserCircle /></Link>
                                    }
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div id="scrollbar">
                    <div id="bar"></div>
                </div>
            </header>
        </>

    )
}

export default Header;


