import Logo from "../footerLogo.svg";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import {Link} from "react-router-dom";


const Footer = ()=> {

    const date = new Date();
    let year = date.getFullYear();

    return(
        <footer className={'site-footer'}>
            <div className={'container'}>
                <div className={'footer-list'}>
                    <div className={'footer-column'}>
                        <ul className={'footer-sublist'}>
                            <li className={'footer-sublist-item logo'}>
                                <img src={Logo} alt={'sahil pate'} />
                            </li>

                            <li className={'footer-sublist-item'}>
                                <a href={'mailto:connect@sahilpate.dev'}><MdEmail/>connect@sahilpate.dev</a>
                            </li>
                            <li className={'footer-sublist-item'}>
                                <span><FaLocationDot/>Nashik, Maharashtra, Bharat</span>
                            </li>
                        </ul>
                    </div>
                    <div className={'footer-column'}>
                        <h4 className={'footer-title'}>Blogs</h4>
                        <ul className={'footer-sublist'}>
                            <li className={'footer-sublist-item'}>
                                <Link to={"https://www.linkedin.com/pulse/connecting-mongodb-node-js-sahil-pate/"} className={"navigation-link"} target={'_blank'} >Connecting MongoDB with Node JS</Link>
                            </li>
                            <li className={'footer-sublist-item'}>
                                <Link to={"https://www.linkedin.com/pulse/spice-up-your-work-developers-guide-making-more-interesting-pate/"} className={"navigation-link"} target={'_blank'}>Spice Up Your Work Developers</Link>
                            </li>
                            <li className={'footer-sublist-item'}>
                                <Link to={"https://www.linkedin.com/pulse/create-widget-using-builder-tool-bigcommerce-sahil-pate/"} className={"navigation-link"} target={'_blank'} >Create widget using Widget Builder tool in BigCommerce</Link>
                            </li>
                            <li className={'footer-sublist-item'}>
                                <Link to={"https://www.linkedin.com/pulse/integrating-bigcommerce-storefront-api-using-graphql-sahil-pate/"} className={"navigation-link"} target={'_blank'} >Integrating BigCommerce Storefront API using GraphQL</Link>
                            </li>
                        </ul>
                    </div>

                    <div className={'footer-column'}>
                        <h4 className={'footer-title'}>Know More</h4>
                        <ul className={'footer-sublist'}>
                            <li className={'footer-sublist-item'}>
                                <Link to={"/about"} className={"navigation-link"} >About</Link>
                            </li>
                            <li className={'footer-sublist-item'}>
                                <Link to={"/blogs"} className={"navigation-link"} >Blogs</Link>
                            </li>
                            <li className={'footer-sublist-item'}>
                                <Link to={"/weather"} className={"navigation-link"} >Weather App</Link>
                            </li>
                        </ul>
                    </div>
                    <div className={'footer-column'}>
                        <h4 className={'footer-title'}>Follow Me</h4>
                        <ul className={'footer-sublist'}>
                            <li className={'footer-sublist-item'}>
                                <Link to={'https://www.linkedin.com/in/sahil-pate/'} target={'_blank'}><FaLinkedinIn />Linkedin</Link>
                            </li>
                            <li className={'footer-sublist-item'}>
                                <Link to={'https://twitter.com/sahilpate7'} target={'_blank'}><BsTwitter />Twitter</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={'copyright'}>
                    <p>© {year} Sahil Pate. All Rights Reserved.</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;


