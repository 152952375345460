import {Link} from "react-router-dom";
import '../../sass/components/button.scss'
import {FaLongArrowAltRight} from "react-icons/fa";

const Button = ({path,title})=>{
    return(
        <Link to={`${path}`} className={'btn'}><span>{title}<FaLongArrowAltRight /></span></Link>
    )
}

export default Button;
