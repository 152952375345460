import Banner from "../components/banner";
import BlogListComponent from "../components/blogs/blogList";

const BlogsPage = ()=> {
    return(
       <>
           <Banner Heading={'Blogs'} />
           <BlogListComponent />
       </>
    )
}

export default BlogsPage;


