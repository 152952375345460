import Banner from "../components/banner";
import ReviewListComponent from "../components/reviews/reviewList";
const ReviewPage = ()=> {
    return(
        <>
            <Banner Heading={'Testimonials'} />
            <ReviewListComponent />

        </>
    );
}

export default ReviewPage;


