import { signInWithEmailAndPassword } from "firebase/auth";
import {auth} from "../../firebase";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const LogInWithEmailAndPassword = ()=>{

    const [userEmail, setUserEmail] = useState();
    const [userPassword, setUserPassword] = useState();
    const [error,setError] = useState();
    const navigate = useNavigate();

    const logIn = ()=>{
        signInWithEmailAndPassword(auth, userEmail, userPassword)
            .then(() => {
                // Signed in
                navigate(-1);
            })
            .catch((error) => {
                setError(error.message);
            });
    }

    return(
        <>
            <h3>Log in</h3>
            <div className={'form'}>
                <div className={'input-field'}>
                    <label className={'label'}>Email</label>
                   <input
                       value={userEmail}
                       placeholder={'Type your email'}
                       onChange={(e)=> setUserEmail(e.target.value)}
                       type={"email"}
                       required={true}
                   />
                </div>
                <div className={'input-field'}>
                    <label className={'label'}>Password</label>
                    <input
                        value={userPassword}
                        placeholder={'Type your password'}
                        onChange={(e) => setUserPassword(e.target.value)}
                        type={"password"}
                    />
                </div>
                {error && <p className={'error'}>{error}</p>}
                <div className={'button-wrap'}>
                <button className={'button'} type={"submit"} onClick={logIn}>Log in</button>
                    <p className={'separator'}>Or</p>
                </div>
            </div>
        </>
    )
}

export default LogInWithEmailAndPassword;
