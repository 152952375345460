import '../../sass/components/activities.scss'
import activitiesList from "../../data/linkedinActivities";
import Slider from "react-slick";


const Blogs = ()=> {
    const settings ={
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows :false,
        autoplay:true,
        autoplaySpeed: 2500,
        responsive:[
            {
                breakpoint: 1023,
                settings:{
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings:{
                    slidesToShow: 1
                }
            }
        ]
    }
    return(
        <section className={'activity-section'}>
            <div className={'container'}>
                <div className={'activity-section-wrap'}>
                    <div className={'content'}>
                        <h2 className={'title'}>LinkedIn Highlights</h2>
                    </div>
                    <Slider className={'list'} {...settings}>
                        {activitiesList.map((e) => (
                            <div className={'list-item'}>
                                <div className={'post'}>
                                    <iframe
                                        frameBorder="0"
                                        src={e.url}
                                    ></iframe>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    )
}

export default Blogs;
