import About from "../components/home/about";
import Banner from "../components/banner";
import Capability from "../components/home/capabilities";
import Skills from "../components/home/skill";
const AboutPage = ()=> {
    return(
        <>
            <Banner Heading={'About'} />
            <About />
            <Skills/>
            <Capability />
        </>
    );
}

export default AboutPage;


