import reviewData from "../../data/reviewsData"
import '../../sass/blogPage/blogList.scss'
import '../../sass/reviewPage/reviewList.scss'
import {Link} from "react-router-dom";
import {FaLinkedin} from "react-icons/fa";

const ReviewListComponent = ()=> {
    return(
        <section className={'reviewListComponent'}>
            <div className={'container'}>
                <ul className={'list'}>
                    {reviewData.map((e)=>(
                        <li className={'list-item'}>
                            <img src={e.picture} alt={e.name}/>
                            <div className={'profile'}>
                                <h3 className={'name'}>{e.name}</h3>
                                <Link className={'link'} to={e.linkedin} target={'_blank'}><FaLinkedin/></Link>
                            </div>
                            <span className={'designation'}>{e.designation}</span>
                            <p className={'review'}>{e.review}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}
export default ReviewListComponent;
