import { createUserWithEmailAndPassword } from "firebase/auth";
import {auth} from "../../firebase";
import {useState} from "react";

const CreateUserWithEmailAndPassword = ()=>{

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [error,setError] = useState();

    const createUser = ()=>{
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                console.log(user);
                // ...
            })
            .catch((error) => {
                setError(error.message);
            });
    }

    return(
        <>
            <h3>Sign up</h3>
            <div className={'form'}>
                <div className={'input-field'}>
                    <label className={'label'}>Email</label>
                    <input
                        value={email}
                        placeholder={'Type your email'}
                        onChange={(e) => setEmail(e.target.value)}
                        type={"email"}
                    />
                </div>
                <div className={'input-field'}>
                    <label className={'label'}>Password</label>
                    <input
                        value={password}
                        placeholder={'Type your password'}
                        onChange={(e) => setPassword(e.target.value)}
                        type={"password"}
                    />
                </div>
                {error && <p className={'error'}>{error}</p>}
                <div className={'button-wrap'}>
                <button className={'button'} type={"submit"} onClick={createUser}>Create Account</button>
                </div>
            </div>
        </>
    )
}

export default CreateUserWithEmailAndPassword;
