import '../../sass/components/skills.scss'
import HTML from '../../assests/icons/html.png'
import CSS from '../../assests/icons/css-3.png'
import javascript from '../../assests/icons/js.png'
import php from '../../assests/icons/php.png'
import shopify from '../../assests/icons/shopify.png'
import wordpress from '../../assests/icons/wordpress.png'
import scss from '../../assests/icons/sass.png'
import bigcommerce from '../../assests/icons/bigcommerce.png'
import jquery from '../../assests/icons/jquery.png'
import react from '../../assests/icons/react.png'
import mongoDb from '../../assests/icons/mongodb.png'
import nodejs from '../../assests/icons/nodejs.png'

const Skills = ()=> {
    const skillsList = [
        {
            name: "HTML",
            src: HTML
        },
        {
            name: "CSS",
            src: CSS
        },
        {
            name: "JavaScript",
            src: javascript
        }
        , {
            name: "React Js",
            src: react
        },
        {
            name: "jQuery",
            src: jquery
        },
        {
            name: "PHP",
            src: php
        },
        {
            name: "Shopify",
            src: shopify
        },
        {
            name: "BigCommerce",
            src: bigcommerce
        } ,
        {
            name: "WordPress",
            src: wordpress
        },
        {
            name: "NodeJs",
            src: nodejs
        },
        {
            name: "MongoDB",
            src: mongoDb
        },
        {
            name: "SCSS",
            src: scss
        }
    ]
    return(
        <section className={'skill-section'}>
            <div className={'container'}>
                <div className={'skill-section-wrap'}>
                    <div className={'content'}>
                        <h2 className={'title'}>Skill Inventory</h2>
                    </div>
                    <ul className={'list'}>
                        {skillsList.map((skill) => (
                            <li className={'list-item'} key={skill.name}>
                                <div className={'list-item-content'}>
                                    <img src={skill.src} alt={skill.name} loading={'lazy'}/>
                                    <h3 className={'name'}>{skill.name}</h3>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    )
}
export default Skills;
