import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import {auth} from "../../firebase";
import {useState} from "react";
import {FcGoogle} from "react-icons/fc";
import {useNavigate} from "react-router-dom";

const LogInWithGoogle =()=>{

    const [errorMessage,setErrorMessage] = useState("");

    const provider = new GoogleAuthProvider();
    const navigate = useNavigate();


    const logIn = ()=>{
        signInWithPopup(auth, provider)
            .then(() => {
                navigate(-1);
            }).catch((error) => {
            setErrorMessage(error.message);
        });
    }
    return(
        <div className={'google-method'}>
            <button className={'button'} type={"button"} onClick={logIn}><FcGoogle />Sign in with Google</button>
            {errorMessage && <p className={'error'}>{errorMessage}</p>}
        </div>
    )
}

export default LogInWithGoogle;
