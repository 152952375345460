import '../../sass/components/testimonial.scss'
import Slider from "react-slick";
import reviewsList from "../../data/reviewsData";
import Button from "../comman/button";
import {FaLinkedin} from "react-icons/fa";
import {Link} from "react-router-dom";
const Testimonial = ()=>{

    const settings ={
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows :false,
        autoplay:true,
        autoplaySpeed: 2500,
        responsive:[
            {
                breakpoint: 1023,
                settings:{
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings:{
                    slidesToShow: 1
                }
            }
        ]
    }

    return(
        <section className={'testimonial-section'}>
            <div className={'container'}>
                <div className={'testimonial-section-wrap'}>
                    <div className={'content'}>
                        <h2 className={'title'}>Testimonials</h2>
                    </div>
                    <div>
                        <Slider {...settings} className={'list'}>
                            {reviewsList.map((reviewer)=>(
                                <>
                                    <img src={reviewer.picture} alt={reviewer.name} loading={'lazy'} />
                                    <div className={'profile'}>
                                        <h3 className={'name'}>{reviewer.name}</h3>
                                        <Link className={'link'} to={reviewer.linkedin} target={'_blank'}><FaLinkedin /></Link>
                                    </div>
                                    <span className={'position'}>{reviewer.designation}</span>
                                    <p className={'description'}>
                                        {reviewer.review.length > 200 ? reviewer.review.substring(0,200) +'...' : reviewer.review}
                                    </p>
                                </>
                            ))}
                        </Slider>
                    </div>
                    <div className={'moreReviewsButton'}>
                        <Button title={'View More'} path={'/reviews'} />
                    </div>

                </div>
            </div>

        </section>
    )
}

export default Testimonial;
