import '../../sass/components/blogs.scss'
import {Link} from "react-router-dom";
import Slider from "react-slick";
import blogList from "../../data/blogsData";
import Button from "../comman/button";


const Blogs = ()=> {
    const settings ={
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows :false,
        autoplay:true,
        autoplaySpeed: 2500,
        responsive:[
            {
                breakpoint: 1023,
                settings:{
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 768,
                settings:{
                    slidesToShow: 1
                }
            }
        ]
    }
    return(
        <section className={'blogs-section'}>
            <div className={'container'}>
                <div className={'blogs-section-wrap'}>
                    <div className={'content'}>
                        <h2 className={'title'}>Recent Articles</h2>
                    </div>
                    <Slider className={'list'} {...settings}>
                        {blogList.slice(0,4).map((e) => (
                            <Link to={e.url} target={'_blank'} className={'list-item'} key={e.name}>
                                <div className={'block'}>
                                    <img src={e.src} alt={e.name} loading={'lazy'}/>
                                    <h3 className={'name'}>{e.name}</h3>
                                    <p className={'content'}>{e.content.substring(0, 200) + '...'}</p>
                                    <span className={'readMore-btn'}>Read more</span>
                                </div>
                            </Link>
                        ))}
                    </Slider>
                    <div className={'moreBlogButton'}>
                        <Button path={'/blogs'} title={'View More'} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Blogs;
