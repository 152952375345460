import Amandeep from '../assests/testimonials/amandeep.jpg'
import RajFartale from '../assests/testimonials/rajFartale.jpg'
import StephanieNagy from '../assests/testimonials/stephanieNagy.jpg'
import JoydeepSarkar from '../assests/testimonials/joydeepSarkar.jpg'
import AmitPalve from '../assests/testimonials/amitPalve.jpg'
import Julio from '../assests/testimonials/julio.jpeg'

const reviewsList = [
    {
        name:"Julio Cavalcanti",
        review : "I had the pleasure to work with Sahil at Coalition Technologies as his team lead. As soon as he joined the team, he demonstrated a great commitment with his growth. He took all the opportunities to learn more and improve his skills more and more. I'm very glad to see all the achievements he got so far and glad to see the great and skilled developer he has become!",
        designation:"BigCommerce Developer",
        picture: Julio,
        linkedin: "https://www.linkedin.com/in/juliocavalcanti"
    },
    {
        name:"Amandeep Singh",
        review : "Sahil is an outstanding developer and an exceptionally hardworking team member. His capacity for quick learning is remarkable. He embraces challenges with enthusiasm and is continually seeking opportunities to enhance his skills. Sahil has skillfully managed complex projects across various platforms, including BigCommerce, Shopify, and WordPress. As a dedicated team player, he readily assists his colleagues whenever they require support. His commitment to going above and beyond guarantees that tasks are completed punctually. I extend my best wishes to him for his future endeavors.",
        designation:"B2B/B2C eCommerce Solutions Architect",
        picture: Amandeep,
        linkedin: "https://www.linkedin.com/in/amandeepsinghvirdi/"
    },
    {
        name:"Raj Fartale",
        review : "I highly recommend Sahil as a colleague and frontend developer. His dedication to delivering high-quality work is commendable, and his collaborative approach greatly enhances team dynamics. Sahil's expertise in frontend development, coupled with his effective communication skills, makes him an asset to any project. He consistently demonstrates a strong commitment to meeting deadlines and exceeding expectations. Working with Sahil has been a pleasure, and I believe his skills and professionalism make him a valuable addition to any team.",
        designation:"Backend Development Manager",
        picture: RajFartale,
        linkedin: "https://www.linkedin.com/in/raj-fartale/"
    },
    {
        name:"Stephanie Nagy",
        review : "I had the pleasure of working with Sahil Pate at Coalition Technologies. Sahil is a dedicated Ecommerce and front end developer who is always learning and improving his skills. Sahil is knowledgeable in BigCommerce, Shopify , and Wordpress and is certified in all three platforms. I am blown away by how many courses he has taken in expert development topics like GraphQL, PHP, web performance, and React. It was no surprise that Sahil's passion and drive led to his being one of the top developers at Coalition Technologies in 2022. I appreciate Sahil's professionalism and would not hesitate to work with him again.",
        designation:"UX Research and Design",
        picture:StephanieNagy,
        linkedin: "https://www.linkedin.com/in/stephanie-nagy-ux-designer/"
    },
    {
        name:"Joydeep Sarkar",
        review : "Sahil is extremely confident and skilled person. Learning new skills is an art in which he is very well versed. He has shown great credibility and assurance whenever a task is assigned to him. He is a team player, and given proper guidance can perform wonders for the organization",
        designation:"DevOps Engineer",
        picture: JoydeepSarkar,
        linkedin: "https://www.linkedin.com/in/getintouchwithjoy/"
    },
    {
        name:"Amit Palve",
        review : "I have had the pleasure of working closely with Sahil and can unequivocally attest to his unwavering dedication and exceptional helpfulness. Sahil consistently demonstrates an unparalleled commitment to his work. His dedication is evident in every task he undertake, consistently going above and beyond to ensure that projects are not just completed, but done so with precision and excellence.",
        designation:"Creative Head",
        picture: AmitPalve,
        linkedin: "https://www.linkedin.com/in/amit-palve-57950b56/"
    }
]
export default reviewsList;
