import NotfoundImg from '../assests/404.gif'
import Button from "../components/comman/button";
import '../sass/components/notFoundPage.scss';

const NotFoundPage = ()=> {
    return(
        <div className={'container'}>
            <section className={'notFoundSection'}>
                <img src={NotfoundImg} alt={'Not Found'} />
                <p>Oops! The page you are looking for can't be found. </p>
                <Button path={'/'} title={'Home'} />
            </section>
        </div>
    )
}

export default NotFoundPage;
