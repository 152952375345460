import Hero from "../components/home/hero";
import About from "../components/home/about";
import Skills from "../components/home/skill";
import Capability from "../components/home/capabilities";
import Blogs from "../components/home/blogs";
import Activities from "../components/home/activities";
import Testimonial from "../components/home/testimonial";

const HomePage = ()=> {
    return(
        <>
            <Hero />
            <About />
            <Skills />
            <Capability />
            <Blogs />
            <Activities />
            <Testimonial />
        </>
    );
}

export default HomePage;


