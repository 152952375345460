import React from 'react';

import useUser from "../account/useUser";
import Button from "../comman/button";
import LoginImg from '../../assests/forgot_password.gif'
const RemoteButton = React.lazy(() => import('remoteApp/App'));


const Weather = () => {

    const {user} = useUser();

    if (!user){
        return (
            <div className={'container'}>
                <section className={'notFoundSection'}>
                    <img src={LoginImg} alt={'Login'} height={350} width={350}/>
                    <p>You need to login to access the app. </p>
                    <Button path={'/login'} title={'Login'}/>
                </section>
            </div>
        )
    }
    if (user) {
        return (
            <React.Suspense fallback="Loading...">
                <div>
                    <RemoteButton />
                </div>
            </React.Suspense>
        )
    }
}

export default Weather;
