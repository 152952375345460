import AboutImage from "../../assests/aboutme.gif";
import '../../sass/components/about.scss'

const About = ()=> {
    return(
        <section id={'about'} className={'about-section'}>
            <div className={'container'}>
                <div className={'about-section-wrap'}>
                    <div className={'info'}>
                        <h2 className={'name'}>Hey!</h2>
                        <p className={'description'}>I am an experienced Frontend Developer with over 4+ years of professional
                            expertise, showcasing a track record of accomplishments in the information technology and
                            services sector. Proficient in HTML, CSS, JavaScript, and React JS, I am presently engaged
                            in eCommerce projects, including but not limited to BigCommerce, WooCommerce, and Shopify
                            platforms. Additionally, I have actively contributed to the creation of technical articles
                            in the field.</p>
                    </div>
                    <div className={'image'}>
                        <img src={AboutImage} alt={'about me'} loading={'lazy'}/>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default About;
