const activitiesList = [

    {
        url:"https://www.linkedin.com/embed/feed/update/urn:li:activity:7124414208682639360"
    },
    {
        url:"https://www.linkedin.com/embed/feed/update/urn:li:activity:7121103051712532481"
    },

    {
        url:"https://www.linkedin.com/embed/feed/update/urn:li:activity:7104513922778431488"
    },
    {
        url:"https://www.linkedin.com/embed/feed/update/urn:li:activity:7097791809682108416"
    },

    {
        url:"https://www.linkedin.com/embed/feed/update/urn:li:activity:7026046179645796352"
    }

]

export default activitiesList;
