
import CreateUserWithEmailAndPassword from "../components/account/createUserWithEmailAndPassword";
import '../sass/account/loginPage.scss'
import {Link} from "react-router-dom";
import PageHeading from "../components/pageHeading";

const SignUpPage = ()=>{
    return(
        <>
            <section className={'account'}>
                <div className={'container'}>
                    <div className={'create-user-section'}>
                        <CreateUserWithEmailAndPassword />
                        <Link to={'/login'} className={'account-link'} >Already have an account? <span> Sign in</span> </Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SignUpPage;
